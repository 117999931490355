<template>
  <v-badge color="red" overlap v-if="cartsItemCount" class="link" bottom left>
    <span slot="badge">{{cartsItemCount}}</span>
    <v-btn
      color="color3 color3Text--text"
      fab
      :class="btnClass"
       @click="goToCart"
       small
    >
      <v-icon class="color3Text--text">fas fa-shopping-cart</v-icon>
    </v-btn>
  </v-badge>
</template>

<script>
export default {
  props: ['btnClass'],
  computed: {
    cartsItemCount () {
      return this.$store.getters.cartsItemCount
    }
  },
  methods: {
    goToCart () {
      this.$router.push({ name: 'checkout' })
    }
  }
}
</script>

<style scoped>
  .link {
    cursor: pointer;
  }
</style>
